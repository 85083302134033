import './App.css';
import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Header from './pages/Header';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
// import Tab3 from './pages/Tab3';
// import Tab4 from './pages/Tab4';
// import Tab5 from './pages/Tab5';
// import Tab6 from './pages/Tab6';
import Footer from './pages/Footer';
import LoginPage from './pages/login';
import Dashboard from './pages/dashboard';
import Tab1CMS from './pages/tab1cms';
import UploadNews from './pages/upload-news';
import UploadTitleDescriptions from './pages/upload-title-descriptions';
import NotFound from './pages/NotFound'; // Import NotFound component
import UploadBanners from './pages/upload-banners';
import UploadLotteryResults from './pages/upload-lottery-results';
import UploadIntroductionApp from './pages/upload-introduction-app';
import UploadLogo from './pages/upload-logo';
import UploadContactInfo from './pages/upload-contact-info';
import UploadFooter from './pages/upload-footer';
import CMSWebApp from './pages/cms-web-app';
import AppDashboard from './pages/app-dashboard';
import AppUploadRegisterLogin from './pages/app-upload-register-login';
import AppUploadMarquee from './pages/app-upload-marquee';
import UploadLotteryResultsAomen from './pages/upload-lottery-results-aomen';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('authToken')
  );

  // Custom hook to detect invalid URLs before the #
  const validateURL = () => {
    // Check if the URL before the # is valid
    if (window.location.pathname !== "/" && window.location.pathname !== "") {
      // Redirect to 404 if it's an invalid URL
      window.location.replace("/#/404");
    }
  };

  useEffect(() => {
    validateURL();
  }, []);

  return (
    <Router>
      <AppContent isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
    </Router>
  );
}

function AppContent({ isAuthenticated, setIsAuthenticated }) {
  const location = useLocation();

  // Hide header/footer for CMS and 404 routes
  const showHeaderFooter = !location.pathname.startsWith('/cms') && location.pathname !== '/404';

  return (
    <div className="app-container">
      {showHeaderFooter && <Header />}
      <main className="content">
        <Routes>
          <Route path="/" element={<Tab1 />} />
          {/* <Route path="/tab1" element={<Tab1 />} /> */}
          <Route path="/tab2" element={<Tab2 />} />
          {/* <Route path="/tab3" element={<Tab3 />} />
          <Route path="/tab4" element={<Tab4 />} />
          <Route path="/tab5" element={<Tab5 />} />
          <Route path="/tab6" element={<Tab6 />} /> */}
          <Route path="/cms" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/cms/cms-web-app" element={isAuthenticated ? <CMSWebApp /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/dashboard/tab1cms" element={isAuthenticated ? <Tab1CMS /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/dashboard/tab1cms/upload-logo" element={isAuthenticated ? <UploadLogo /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/dashboard/tab1cms/upload-news" element={isAuthenticated ? <UploadNews /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/dashboard/tab1cms/upload-banners" element={isAuthenticated ? <UploadBanners /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/dashboard/tab1cms/upload-contact-info" element={isAuthenticated ? <UploadContactInfo /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/dashboard/tab1cms/upload-lottery-results" element={isAuthenticated ? <UploadLotteryResults /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/dashboard/tab1cms/upload-lottery-results-aomen" element={isAuthenticated ? <UploadLotteryResultsAomen /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/dashboard/tab1cms/upload-introduction-app" element={isAuthenticated ? <UploadIntroductionApp /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/dashboard/tab1cms/upload-title-descriptions" element={isAuthenticated ? <UploadTitleDescriptions /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/dashboard/tab1cms/upload-footer" element={isAuthenticated ? <UploadFooter /> : <Navigate to="/cms" />} />

          <Route path="/cms/cms-web-app/app-dashboard" element={isAuthenticated ? <AppDashboard /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/app-dashboard/app-upload-register-login" element={isAuthenticated ? <AppUploadRegisterLogin /> : <Navigate to="/cms" />} />
          <Route path="/cms/cms-web-app/app-dashboard/app-upload-marquee" element={isAuthenticated ? <AppUploadMarquee /> : <Navigate to="/cms" />} />


          <Route path="*" element={<NotFound />} /> {/* 404 page */}
        </Routes>
      </main>
      {showHeaderFooter && <Footer />}
    </div>
  );
}

export default App;
